import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import withAppconfig from '../AppContext'

import {
  CardBody,
  CardTitle,
  CardText,
  Row,
  Col,
  Alert,
  Spinner,
} from 'reactstrap'
import { Divider } from '@material-ui/core'
import moment from 'moment'
import { connect } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'

const enhance = connect(
  // Map redux state to component props
  ({ firebase: { auth } }) => ({
    auth
  })
)

function ReservationItem({ reservation, appconfig, account, auth, memberships, orgMemberships }) {
  const { t } = useTranslation()
  const firebase = useFirebase()
  const [pricescheck, setPricesCheck] = useState(true)
  const [membershipprices, setMembershipPrices] = useState(null)
  const [selectedmembership, setSelectedmembership] = useState(null)

  const isBusiness = account ? account.type === 'organisation' : false

  const getPrices = (slot) => {
    console.log('getPrices', account)
    firebase.functions().region = 'europe-west1'
    const fn = appconfig.istest ? 'testui-get' : 'ui-get'
    const getprice = firebase.functions().httpsCallable(fn)
    setPricesCheck(true)
    getprice({ q: 'getPrice', slot, personid: auth.uid, customerid: appconfig.accountkey, organisation: account ? account.id : 'personal' }).then((prices) => {
      if (prices.data && prices.data.prices && prices.data.prices.length > 0) {
        prices.data.prices.sort((a, b) => a.price - b.price)
      }
      let membershipids = []
        
      if(!isBusiness) {
        membershipids = Object.keys(memberships || {}).map((key) => {
          return memberships[key].membershipid
        })
      } else {
        console.log('orgMemberships', orgMemberships)
        membershipids = orgMemberships ? orgMemberships.map((x) => x.membershipid) : []
      }

      let membershipprices = prices.data.prices.filter((x) => {
        if (x.membershipid) {
          return membershipids.includes(x.membershipid)
        } else {
          return true
        }
      })
      if (membershipprices && membershipprices.length > 0) {
        reservation.price = membershipprices[0].price
      }
      if (membershipprices.length > 1) {
        reservation.overridecost = true
        const gen = membershipprices.find((x) => x.pricing === 'general')
        reservation.general = gen
        setSelectedmembership(membershipprices[0].pricing)
      }
      setMembershipPrices(membershipprices)
      setPricesCheck(false)
      // setPricesCheckRd(false)
    })
  }

  useEffect(() => {
    if (!reservation) return
    const slot = {
      start: reservation.start,
      end: moment(reservation.start).add(reservation.duration, 'minutes').toDate(),
      resourceId: reservation.resourceId,
      a: account ? account.id : 'personal',
    }
    if (slot) {
      getPrices(slot)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservation, account])

  return (
    <CardBody key={reservation.id}>
      <CardTitle tag='h5'>{reservation.displayName}</CardTitle>
      <Row className={'loose'}>
        <Col>{t('day')}</Col>
        <Col style={{ textTransform: 'capitalize' }} className={'text-right'}>
          {moment.utc(reservation.start).format('dddd D.M.YYYY')}
        </Col>
      </Row>
      <Divider />
      <Row className={'loose'}>
        <Col>{t('time')}</Col>
        <Col className={'text-right'}>
          {moment.utc(reservation.start).format('H:mm')}
        </Col>
      </Row>
      <Divider />
      <Row className={'loose'} key={'duration'}>
        <Col>{t('duration')}</Col>
        <Col className={'text-right'}>
          {reservation.duration} {t('minutes')}
        </Col>
      </Row>
      <Divider />

      {reservation.customeralert ? (
        <>
          <Divider />
          <Row className={'loose'} key={'customeralert'}>
            <Col className={'text-left'}>
              <Alert style={{ marginBottom: 0 }}>
                {reservation.customeralert}
              </Alert>
            </Col>
          </Row>
        </>
      ) : null}

      <Divider />
      {pricescheck ? (
        <Spinner size='sm' />
      ) : membershipprices ? (
        membershipprices.map((p, i) => {
          return (
            <Row key={'membership-prices-' + i} className={'loose'}>
              <Col>{p.pricing === 'general' ? t('price') : p.pricing}</Col>
              <Col className={'text-right'}>
                {i >= 1 ? (
                  <s>{p.price.toFixed(2).replace('.', ',')} €</s>
                ) : (
                  p.price.toFixed(2).replace('.', ',') + ' €'
                )}
                {p.lastminute ? ' ' + t('lastminute_discount') : ''}
              </Col>
            </Row>
          )
        })
      ) : (
        <Row className={'loose'} key={'price'}>
          <Col>{t('price')}</Col>
          <Col className={'text-right'}>
            {reservation.price.toFixed(2).replace('.', ',')} €
          </Col>
        </Row>
      )}

      <CardText>
      </CardText>
    </CardBody>
  )
}

export default withAppconfig(enhance(ReservationItem))
